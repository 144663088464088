<template>
  <div class="script">
    <p class="title text-center">Настройки системы</p>
    <v-card>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(setting,i) in settings"
            :key="i"
          >
          <v-list-item-title>
            {{setting.comment}}
          </v-list-item-title>
          <v-text-field
            v-model="setting.value"
            @change="saveSetting(setting)"
            dense
            hide-details
          />
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: ()=>({
    projSettings: [
      "object_name", "object_place", "work_start", "work_stop", "night_start", "night", "day_stop", "light_treshold", "light_hyst", "light_delay",
    ],
  }),
  mounted() {
    // this.$store.dispatch('getSettings')
  },
  computed: {
    settings() {
      return this.$store.state.settings
    },
  },
  methods: {
    saveSetting (settingRecord) {
      this.$store.dispatch('saveSetting', settingRecord)
    }
  }
}
</script>

<style>

</style>